<template>
  <section class="personal-details">
    <!-- name, email -->
    <!-- Add password change dialog -->
  </section>

  <section class="search-details">
    <div>
      <h2 id="excluded-words">Excluded Words</h2>
      <textarea class="search-blacklist">{{ searchBlackList }}</textarea>
    </div>
  </section>

  <section class="saved-items">
    <div>
      <h2 id="saved-items">Saved Items</h2>
      <ul v-for="item in savedItems" v-key="item.id">
        <li><a :href="item.uri">{{ item.name }}</a></li>
      </ul>
    </div>
  </section>

</template>

<script>
  export default {
    data() {
      return {
      }
    },
    props: {
      userName: String,
      userEmail: String,
      searchBlackList: String,
      searchHistory: Array,
      savedItems: Array
    },
    mounted() {
      // this.parseUserDetails(this.user);
    },
    methods:
    {
      parseUserDetails(details)
      {
        let result = JSON.parse(details);
      }
    }
  }
</script>
